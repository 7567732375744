.landing-page {
    font-family: 'Inter', sans-serif;
    color: var(--foreground);
    line-height: 1.5;
    margin: 0 auto;
}

.body-lock {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--border);
    background-color: var(--background);
    height: 62px; /* Set a fixed height */
    box-sizing: border-box; /* Include padding in the height calculation */
}

.landing-main section {
    padding: 2rem 0;
}

.landing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
}

.featured-coupons,
.our-partners,
.how-it-works,
.join-community {
    padding-top: 0;
    margin-bottom: 0; 
    padding: 0;
    text-align: center;
}

.featured-coupons {
    padding-top: 0;
    background-color: #f7f7f7;
}

.our-partners {
    background-color: #f7f7f7;
}

.how-it-works {
    background-color: #e8edf1;
}

.join-community {
    background-color: #e8edf1;
}

h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.coupon-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}

.no-vouchers-message {
    grid-column: 1 / -1;
    text-align: center;
}

.coupon-card {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 0.5rem;
    padding: 1.5rem;
    text-align: left;
}

.coupon-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.discount-badge {
    background-color: #e53e3e;
    color: #fff;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: bold;
}

.expiry-date {
    font-size: 0.875rem;
    color: #666;
}

.get-coupon-link {
    display: inline-flex;
    align-items: center;
    color: #e53e3e;
    text-decoration: none;
    margin-top: 1rem;
}

.get-coupon-link::after {
    content: '→';
    margin-left: 0.5rem;
}

.partner-grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Change auto-fill to auto-fit */
    gap: 1rem;
    align-items: center;
}

.partner-grid p{
    grid-column: 1 / -1; /* Span all columns */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
}

.partner-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}

.partner-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step svg {
    width: 48px;
    height: 48px;
    color: #e53e3e;
}

.step-title {
    margin: 0.5rem 0rem 0.5rem 0rem;
}

.step-description {
    
    margin: 0rem 0rem 1rem 0rem;
}

.get-started-button {
    background-color: #e53e3e;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.get-started-button:hover {
    background-color: #c53030;
}

.terms {
    font-size: 0.875rem;
    color: #666;
    margin-top: 1rem;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--border);
    background-color: white;
    height: 62px; /* Set a fixed height */
    box-sizing: border-box; /* Include padding in the height calculation */
}

.header-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.settings-icon {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--foreground);
    padding: 0;
    display: flex;
    align-items: center;
}

.auth-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.auth-form-title {
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
}

.get-started-button,
.login-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.get-started-button {
    background-color: #e53e3e;
    color: #fff;
}

.get-started-button:hover {
    background-color: #c53030;
}

.login-button {
    background-color: #4a5568;
    color: #fff;
}

.login-button:hover {
    background-color: #2d3748;
}

@media (min-width: 768px) {
    .main-nav {
        display: flex;
    }

    .coupon-grid{
        grid-template-columns: repeat(3, 1fr);
    }

    .steps-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .partner-grid {
        grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    }

    .partner-logo {
        height: 60px;
    }
}

.join-community {
    text-align: center;
}

.join-community h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.join-community p {
    margin-bottom: 2rem;
}

.auth-form-container {
    max-width: 400px;
    margin: 0 auto;
}

.auth-form {
    margin-bottom: 1rem;
}

.create-account-button,
.sign-in-button {
    display: block;
    width: 100%;
    padding: 0.75rem 1.5rem;
    margin-bottom: 1rem;
    background-color: #e53e3e;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-account-button:hover,
.sign-in-button:hover {
    background-color: #c53030;
}

.sign-in-button {
    background-color: #4a5568;
}

.sign-in-button:hover {
    background-color: #2d3748;
}

.terms-agreement {
    font-size: 0.875rem;
    margin-bottom: 0rem !important; 
    color: #666;
}

.terms-link {
    color: #e53e3e;
    text-decoration: none;
}

.terms-link:hover {
    text-decoration: underline;
}

.auth-divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1rem 0;
}

.auth-divider::before,
.auth-divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
}

.auth-divider span {
    padding: 0 0.5rem;
    background-color: #f8f9fa;
    color: #666;
}

h4 {
    margin-bottom: 10px;
    color: #333;
}

.section-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.terms-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .terms-overlay .terms-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }

  .advertise-events {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f7f7f7;
    text-align: center;
}

.advertise-featured-event {
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    align-items: center;

}

.advertise-featured-event-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.advertise-featured-event-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
}

.advertise-featured-event-image {
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.advertise--featured-event-wrapper {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: #e8edf1;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.advertise-featured-event-content {

    text-align: left;
}

.advertise-event-details {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.advertise-event-date,
.advertise-event-location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.advertise-event-button {
    width: 100%;
    background-color: #4a5568;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.advertise-events .view-all-events-button {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #e53e3e;
    color: white;
    border-radius: 4px;
    text-decoration: none;
}

