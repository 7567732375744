.voucher-display-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1010; /* Ensure the overlay has a higher z-index */
}

.voucher-display {
    position: fixed;
    z-index: 1010;
    top: 20%;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100vh; /* Constrain the height of the display */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
    animation: slideUp 0.3s ease-out;
    overflow: hidden; /* Prevent overflow */
}


@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.voucher-display-close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    z-index: 1;
}

.voucher-display-header {
    background-color: #d0d0d0;
    padding: 15px;
    text-align: center;
    position: sticky; /* Use sticky positioning */
    top: 0; /* Stick to the top */
    z-index: 2; /* Ensure it stays above the content */
}

.voucher-display-header h2 {
    margin: 0;
    font-size: 1.2rem;
}

.voucher-display-content {
    flex-grow: 1;
    overflow-y: auto; /* Enable scrolling */
    max-height: 100%; /* Allow it to take the rest of the space */
    padding: 10px 10px 0px 10px;
    text-align: center;

    align-items: center;    
}

.voucher-display-logo {
    width: 80px;
    height: 80px;
}

.voucher-display-title {
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
}

.voucher-display-discount {
    font-size: 2rem;
    font-weight: bold;
    color: #ffc300;
    margin: 10px 0;
}

.voucher-display-details {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0 0;
    text-align: left;
}

.voucher-display-details li {
    margin-bottom: 5px;
    font-size: 0.9rem;
}

/* Add styles for the dashed line divider */
.voucher-display-divider {
    width: 70%; /* Adjust width to 80% of the voucher section */
    border-top: 3px dashed #ddd; /* Use dashed line instead of dotted */
    margin: 10px auto; /* Center the divider */
}

.voucher-display-qr {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.voucher-display-share {
    margin: 10px 0 0 0;
    font-size: 0.9rem;
    color: #666;
}

.voucher-display-terms {

    font-size: 0.9rem;
    padding: 10px 0;
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.voucher-display-terms::after {
    content: '▼';
    margin-left: 5px;
    font-size: 0.8rem;
    transition: transform 0.3s ease;
}

.voucher-display-terms.expanded::after {
    transform: rotate(180deg);
}

.voucher-display-terms-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    font-size: 0.8rem;
    color: #333;
    text-align: left;
    padding: 0 15px;
}

.voucher-display-terms-content.expanded {
    text-align: center;
    padding-bottom: 20px;
}

.voucher-display-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}

/* Add these styles at the end of the file */

.voucher-display-card,
.voucher-display-card * {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.voucher-display-card:focus,
.voucher-display-card:active {
  background-color: #FFD700; /* Keep the same background color */
  outline: none;
}

.voucher-display-map-link {
  color: #007bff;
  text-decoration: none;
  margin-left: 5px;
}

.voucher-display-map-link:hover {
  text-decoration: underline;
}

.voucher-display-redeemable {
  margin: 10px 0;
  font-size: 0.9rem;
}


.voucher-display.preview-mode {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  max-height: 500px;
  overflow-y: auto;
  margin: 0;
  animation: none;
}

.preview-mode .voucher-display-overlay {
  display: none;
}

.preview-mode .voucher-display-close-button {
  display: none;
}

.terms-and-conditions {
  white-space: pre-wrap;
  word-wrap: break-word;
}

