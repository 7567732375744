.register-container {
    max-width: 100%;
    margin: 0 0 0 0;
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    height: auto; /* Allow container to grow */
    overflow-y: visible; /* Remove scroll */
  }
  
  .register-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
  }
  
  .error-message {
    color: red;
    margin-bottom: 15px;
    font-size: 0.9em;
  }
  

  .form-group {
    margin-bottom: 15px;
    position: relative;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9em;
  }

  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .marketing-info {
    font-size: 0.8em;
    color: #666;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .register-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 20px;
  }
  
  .register-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 480px) {
    .register-container {
      padding: 10px;
    }
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
  }
  
  .form-row .form-group {
    width: 48%;
  }
  
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9em;
  }
  
  .disabled-link {
    color: #999;
    pointer-events: none;
  }
  
  .terms-text {
    font-size: 0.8em;
    margin-bottom: 5px; /* Reduce space between text and button */
  }
  
  @media (max-width: 480px) {
    .form-row {
      flex-direction: column;
    }
    
    .form-row .form-group {
      width: 100%;
    }
  }
  
  .date-of-birth {
    display: flex;
    gap: 10px;
  }

  .date-of-birth select,
  .date-of-birth input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9em;
  }

  button[type="submit"], .register-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;
    font-family: 'Roboto', Arial, sans-serif; /* Match the font family */
    font-size: 14px; /* Adjust the font size */
    font-weight: 500; /* Add some weight to the text */
    letter-spacing: 0.5px; /* Optional: add some letter spacing */
  }

  button[type="submit"]:hover, .register-button:hover {
    background-color: #0056b3;
  }

  .terms-and-button {
    display: flex;
    flex-direction: column;
  }

  .register-header{
    text-align: center;
  }

  .register-header-title{
    margin: 0;
    padding-bottom: 5px;
     padding-top: 0px;
  }

  @media (max-height: 630px) {
  .register-header-title{
    margin-top: -10px;
     padding-top: 0px;
     
    padding-bottom: 0px;
  }
}

.register-password-title{
  margin-top: 0px;
  margin-bottom: 0px;

}

@media (max-height: 680px) {
.register-password-title{
    margin-top: 0px;

  }
}

.back-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  margin-bottom: 10px;
  text-align: left;
}

.register-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (min-height: 800px) {
  .register-container {
    padding: 20px 20px;
  }

  .form-group {
    margin-bottom: 20px;
  }
}

.password-rules {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
  margin-bottom: 0;
  font-size: 0.9em;
}

.password-rules.visible {
  max-height: 200px; /* Adjust this value based on your content */
  opacity: 1;
  margin-bottom: 10px;
}

.password-rules ul {
  list-style-type: none;
  padding-left: 0;
  margin: 5px 0;
}

.password-rules li {
  margin-bottom: 5px;
  color: #666;
}

.password-rules li.met {
  color: #4CAF50;
}

.password-rules li::before {
  content: '•';
  margin-right: 5px;
}

.password-rules li.met::before {
  content: '✓';
  color: #4CAF50;
}

.form-group {
  position: relative;
}

.password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.password-toggle {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #666;
}
