.ticket-card {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  margin: 1rem;
  transition: box-shadow 0.3s ease;
  position: relative;
  border: 1px solid hsl(var(--border));
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  color: #000000;
  margin-bottom: 1rem;
}

.ticket-card:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.ticket-header {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.ticket-title {
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 1rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.ticket-content {
  padding: 1rem 1.5rem;
}

.ticket-info {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0.25rem 0;
}

.ticket-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket-type-badge {
  font-size: 0.75rem;
  font-weight: 500;
  color: #374151;
  background-color: #e5e7eb;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
}

.ticket-status-badge {
  font-size: 0.75rem;
  font-weight: 500;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
}

.status-active {
  background-color: #10b981;
}

.status-used {
  background-color: #6b7280;
}

.status-expired {
  background-color: #ef4444;
}

.status-default {
  background-color: #3b82f6;
}

.ticket-card.expired {
  opacity: 0.7;
  background-color: #f0f0f0;
}

.status-expired {
  background-color: #999;
  color: #fff;
}

