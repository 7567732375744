.terms-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.terms-popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.terms-content {
  margin-bottom: 20px;
  padding: 20px 0; /* Add padding to top and bottom */
  position: relative;
}

.terms-popup h2 {
  margin-top: 20px; /* Add space above the title for the top back button */
}

.back-button {
  display: block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin: 10px 0;
}

.top-back-button {
  position: sticky;
  top: 10px;
  z-index: 1001;
}

.bottom-back-button {
  margin: 20px auto 0;
}

@media (max-width: 600px) {
  .terms-content {
    padding: 40px 0; /* Increase padding for mobile to accommodate the back buttons */
  }

  .top-back-button,
  .bottom-back-button {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
  }

  .top-back-button {
    top: 10px;
  }

  .bottom-back-button {
    bottom: 10px;
  }
}

