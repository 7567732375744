.contact-us {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.contact-us h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.contact-us p {
  line-height: 1.6;
  color: #666;
}

.contact-info {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  padding: 15px;
  margin: 20px 0;
}

.contact-info p {
  margin: 0;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.description {
  margin-top: 30px;
}

.description h2 {
  color: #333;
  margin-bottom: 15px;
}

.back-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-link:hover {
  background-color: #0056b3;
}
