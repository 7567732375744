.work-with-us {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.work-with-us h1 {
  text-align: center;
  color: #007bff;
  margin-bottom: 30px;
  font-size: 2.5em;
}

.work-with-us h2 {
  color: #0056b3;
  margin-top: 30px;
  margin-bottom: 15px;
}

.work-with-us p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.work-with-us ul, .work-with-us ol {
  padding-left: 20px;
  margin-bottom: 15px;
}

.work-with-us li {
  margin-bottom: 10px;
}

.work-with-us a {
  color: #007bff;
  text-decoration: none;
}

.work-with-us a:hover {
  text-decoration: underline;
}

.back-link {
  display: inline-block;
  margin-top: 30px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white !important; /* Ensure text is white and overrides any inherited styles */
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-link:hover {
  background-color: #0056b3;
  text-decoration: none !important; /* Prevent underline on hover */
}
