body {
  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
  font-display: swap; /* This allows fallback fonts to be used until the custom font is loaded */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

html {
  -webkit-box-sizing: border-box;
       box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
      box-sizing: inherit;
}