.loading-bar {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .loading-bar-progress {
    height: 10px;
    background-color: #4caf50;
    width: 0;
    transition: width 0.2s;
  }