.confirmation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 41px);
    background-color: #e8edf1;
    padding: 20px;
}

.confirmation-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 600px;
    width: 100%;
}

.confirmation-card h2 {
    color: #2c3e50;
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 600;
}

.order-details {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
}

.order-details p {
    margin: 12px 0;
    color: #34495e;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
}

.order-details strong {
    font-weight: 600;
    color: #2c3e50;
}

.qr-code-container {
    display: flex;
    justify-content: center;
    margin: 30px 0;
}

.qr-code-container svg {
    width: 200px;
    height: 200px;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.print-button, .email-button {
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
    font-weight: 500;
}

.print-button {
    background-color: #3498db;
    color: white;
}

.email-button {
    background-color: #2ecc71;
    color: white;
}

.print-button:hover, .email-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.email-button:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
}

.email-sent {
    text-align: center;
    color: #27ae60;
    margin-top: 15px;
    font-weight: 500;
}

.back-to-home {
    display: block;
    text-align: center;
    margin-top: 30px;
    color: #3498db;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.back-to-home:hover {
    color: #2980b9;
}

.loading, .error {
    text-align: center;
    color: #2c3e50;
    font-size: 20px;
    margin-top: 50px;
}

.error {
    color: #e74c3c;
}

@media print {
    .action-buttons, .back-to-home {
        display: none;
    }
    .confirmation-card {
        box-shadow: none;
    }
}

@media (max-width: 600px) {
    .confirmation-card {
        padding: 20px;
    }

    .action-buttons {
        flex-direction: column;
    }

    .print-button, .email-button {
        width: 100%;
        margin-bottom: 15px;
    }

    .order-details p {
        flex-direction: column;
    }

    .qr-code-container svg {
        width: 150px;
        height: 150px;
    }
}

.pending-message,
.failed-message {
    text-align: center;
    margin: 20px 0;
}

.retry-purchase {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.retry-purchase:hover {
    background-color: #0056b3;
}

.voucher-previews {
    margin-top: 30px;
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
}

.voucher-previews h3 {
    font-size: 18px;
    color: #2c3e50;
    margin-bottom: 15px;
}

.voucher-preview-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    padding-bottom: 15px;
}

.voucher-preview-list .voucher-card {
    width: 100%;
    max-width: none;
}

.view-all-vouchers {
    display: block;
    text-align: center;
    margin-top: 20px;
    color: #3498db;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.view-all-vouchers:hover {
    color: #2980b9;
}

@media (max-width: 600px) {
    .voucher-preview-list {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
}

.confirmation-voucher-previews .voucher-card {
  cursor: pointer;
  height: 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.confirmation-voucher-previews .voucher-card .voucher-image {
  height: 100px;
  overflow: hidden;
}

.confirmation-voucher-previews .voucher-card .voucher-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.confirmation-voucher-previews .voucher-card .company-logo {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-voucher-previews .voucher-card .company-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.confirmation-voucher-previews .voucher-card .voucher-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.confirmation-voucher-previews .voucher-card .company-description,
.confirmation-voucher-previews .voucher-card .voucher-description {
  margin: 0;
  font-size: 12px;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.confirmation-voucher-previews .voucher-card .details {
  font-size: 10px;
  color: #888;
  margin: 5px 0 0;
}

.confirmation-voucher-previews .voucher-card .voucher-ribbon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 3px;
}
