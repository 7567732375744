.event-financials {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 15px;
  margin-top: 15px;
}

.event-financials .financial-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.event-financials .financial-item span:first-child {
  font-weight: bold;
}
