:root {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;
  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: 346.8 77.2% 49.8%;
  --primary-foreground: 355.7 100% 99%;
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 45%;
  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 72% 51%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;
  --ring: 346.8 77.2% 49.8%;
  --radius: 0.5rem;
}

/* Reset default margins and set full height */
html, body {
  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--foreground);
}

.home-page {
    max-width: 600px; 
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 41px);
    
    background-color: #e8edf1;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid black; /* Add this line */
}

.header-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.settings-icon {
    background: none;
    border: none;
    cursor: pointer;
}

.coming-soon-section-background{
    flex-grow: 1;
}

.cards-section-background, .expired-tickets-section-background {
  display: flex;
  justify-content: center;
  padding: 0rem;
}

.cards-section, .expired-tickets-section {
  background-color: hsl(var(--card));
  border-radius: var(--radius);
  margin: 1rem;
  border: 1px solid hsl(var(--border));
  overflow: hidden; 
  max-width: 600px;
  width: 100%;
}

.cards-content, .expired-tickets-section {
  padding: 0 1rem; /* Add horizontal padding to align content */
}

.cards-title, .expired-tickets-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: hsl(var(--foreground));
}

.expired-tickets-section {
  margin-top: 0;
}


.expired-tickets-content {
    padding: 0 1rem;
}

.tickets-list, .expired-tickets-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.body-scroll-lock {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.vouchers-section-background {
  background-color: #f0f4f8;
  padding: 1rem 0;
}

.vouchers-section {
    box-sizing: border-box;
    width: 100%;
    border-radius: var(--radius);
    margin: 0 auto;
    padding: 1rem;
    overflow: hidden; 
    max-width: 1200px;
}

.vouchers-title {
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 0;
    font-size: 1.5rem;
    color: hsl(var(--foreground));
}

.vouchers-description {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: hsl(var(--muted-foreground));
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.voucher-list {
    display: grid;
    gap: 1rem;
    justify-content: center;
}

/* Specific adjustments for 1 or 2 vouchers */
.voucher-list.single-voucher {
    grid-template-columns: minmax(0, 400px);
}

.voucher-list.two-vouchers {
    grid-template-columns: repeat(2, minmax(0, 400px));
}

@media (max-width: 500px) {
    .voucher-list.two-vouchers {
        grid-template-columns: 1fr;
    }
}

.coming-soon-section-background {
  padding: 1rem 0;
}

.coming-soon-section {
  max-width: 600px;
  margin: 1rem;
}

.cards-tabs {
  display: flex;
  width: 100%;
}

.cards-tab {
  flex: 1;
  padding: 1rem;
  font-weight: 600;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: hsl(var(--muted-foreground));
}

.cards-tab.active {
    background-color: hsl(var(--background));
    color: hsl(var(--primary));
    border-bottom: 2px solid hsl(var(--primary));
}

.cards-tab:not(.active) {
    background-color: hsl(var(--muted));
    color: hsl(var(--muted-foreground));
}

.card {
    background-color: #9dd1a2; /* The specific color you provided */
    border-radius: var(--radius);
    padding: 1rem;
    margin-bottom: 1rem;
    position: relative;
    border: 1px solid hsl(var(--border));
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    
  word-break: break-word;
  overflow-wrap: break-word;
}

.card-name {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.2rem;
    color: hsl(var(--card-foreground)); /* Ensure text is visible on the new background */
}   

.card-number {
    font-size: 0.875rem;
    margin: 0;
    color: hsl(var(--card-foreground));
}

.available {
    font-size: 0.75rem;
    color: hsl(var(--card-foreground));
    opacity: 0.8; /* Slightly muted */
    margin: 0;
}

.valid-date {
    font-size: 0.75rem;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    margin-bottom: 0;
    color: hsl(var(--card-foreground));
    opacity: 0.8; /* Slightly muted */
}

.qr-code-icon img {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 24px;
    height: 24px;
}

.home-page .voucher-card {
    height: auto;
    position: relative;
    border: 1px solid var(--border);
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 1rem;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    background-color: white;
}

.voucher-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.voucher-ribbon {
    position: absolute;
    top: 0;
    right: 5px;
    width: 30px;
    height: 100px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 85%, 0 100%);   
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    padding-top: 5px;
}

.voucher-ribbon span{
    color: white;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    text-align: center; 
    writing-mode: vertical-rl;
}

.voucher-ribbon.vip {
    background-color: #ff4081;
}

.voucher-ribbon.trial {
     background-color: #4caf50;
}

.voucher-ribbon.trial span{
    top: 20px;
    left: 7px;
}
.vip-crown {
    width: 12px;
    height: 12px;
    margin-bottom: 5px;
}

.voucher-image {
    width: 100%;
    height: 120px;
    position: relative;
    overflow: hidden;
}

.voucher-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.company-logo img {  
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}



.voucher-content h4 {
    margin: 0 0 0.25rem 0;
    font-size: 1rem;
    color: #333;
    font-weight: 600;
}

.voucher-content .discount {
    margin: 0;
    font-size: 1.5rem;
    color: #e53935;
    font-weight: bold;
}


.vouchers-title{
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 0;
}
.voucher-content .details {
    margin: 0.25rem 0 0 0;
    font-size: 0.8rem;
    color: #b5b5b5;
}

.coming-soon-section {
    background-color: hsl(var(--card));
    border-radius: var(--radius);
    max-height: 100px;
    flex-grow: 0 !important;
    border-radius: 0.5rem;
    padding: 1rem;
    text-align: center;
    border: 1px solid hsl(var(--border));
    overflow: hidden; 
    max-width: 600px; 
}



.coming-soon-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.coming-soon-text {
    font-size: 0.875rem;
    font-weight: 600;
}

* {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.expired-tickets-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  .toggle-expired-tickets {
    background: none;
    border: none;
    color: hsl(var(--muted-foreground));
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .toggle-expired-tickets:hover {
    background-color: hsl(var(--accent));
    color: hsl(var(--accent-foreground));
  }
  
  .toggle-expired-tickets svg {
    width: 20px;
    height: 20px;
  }
  

/* Responsive adjustments */
@media (min-width: 600px) {

    .home-page {
        max-width: 100%;
    }

    .coming-soon-section-background{
        display: flex;
        justify-content: center; /* Center the cards section horizontally */

    }

    .coming-soon-section{
        overflow: hidden;
        max-width: 600px; /* Set a max-width for larger screens */
        width: 100%; /* Take full width on smaller screens */
    
    }
    
    .cards-section-background{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .cards-section{
        max-width: 600px;      
        width: 100%; /* Take full width on smaller screens */
    }

    .profile-main {
        max-width: 100%;
    }

    .voucher-grid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .vouchers-section {
        max-width: 800px;
        margin: 0 auto;

    }

    .voucher-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .expired-tickets-section-background {
      width: 100%;
      display: flex;
      justify-content: center;
    }

}

/* For 3 or more vouchers on large screens */
@media (min-width: 1000px) {
    .vouchers-section {
        max-width: 1200px;
    }
    .voucher-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* For 2 columns between 1000px and 501px */
@media (max-width: 999px) and (min-width: 501px) {
    .voucher-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

/* For 1 column on small screens */
@media (max-width: 500px) {


    .vouchers-title {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .voucher-list {
        grid-template-columns: 1fr;
    }
}

.cards-content {
  background-color: hsl(var(--card));
  border-radius: var(--radius);
  overflow: hidden;
}

.cards-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: hsl(var(--background));
}

.cards-list {
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out, padding 0.3s ease-out;
  overflow: hidden;
  opacity: 1;
  max-height: 1000px; /* Adjust this value based on your needs */
  padding: 1rem;
}

.cards-list.collapsed {
  max-height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.toggle-cards-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.toggle-cards-button:hover {
  background-color: hsl(var(--accent));
}

.cards-content.expanded .toggle-cards-button {
  transform: rotate(180deg);
}

.home-page .no-tickets {
  text-align: center;
  margin-top: 1rem;
} 

/* Add this to your existing CSS file */
.admin-controls {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  background-color: #f0f0f0;
}

.admin-controls a {
  margin-left: 15px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
}

.admin-controls a:hover {
  background-color: #0056b3;
}

