:root {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;
  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: 346.8 77.2% 49.8%;
  --primary-foreground: 355.7 100% 99%;
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 45%;
  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 72% 51%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;
  --ring: 346.8 77.2% 49.8%;
  --radius: 0.5rem;
}


.settings-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 41px);
  background-color: #f0f4f8;
}

.header-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: var(--foreground);
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: var(--foreground);
  display: flex;
  align-items: center;
}

.header-right-placeholder {
  width: 24px; /* Match the width of the settings icon */
}

.settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.settings-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.settings-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: hsl(240deg 21.65% 19.02%);
  color: hsl(var(--primary-foreground));
  padding: 1.5rem;
  margin-bottom: 1rem;
}

.avatar {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: hsl(346.8deg 5.22% 21.09%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.user-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.5rem 0;
  color: hsl(var(--primary-foreground));
}
.settings-main-block {
  max-width: 800px;
  width: 90%;
  padding: 1rem;
}

.settings-form {
  width: 90%;
  max-width: 400px;
  border-radius: var(--radius);
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #8B4513;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: var(--foreground);
}

/* New styles for the phone number input */
.form-group input[readonly] {
  background-color: #e9e9e9;
  border: 1px solid #d1d1d1;
  color: #666;
  cursor: default;
}

.form-group input[readonly]:focus {
  outline: none;
  box-shadow: none;
}

.settings-actions {
  width: 90%;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-top: 2rem;
}

.save-button,
.logout-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.save-button {
  background-color: #4CAF50;
  margin-bottom: 1rem;
}

.save-button:hover {
  background-color: #45a049;
}

.logout-button {
  background-color: #001F3F;
}

.logout-button:hover {
  background-color: #00152A;
}