.event-management {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.event-form-container {
    border: 2px solid #007bff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    background-color: #f8f9fa;
}

.event-management form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-section {
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 15px;
    background-color: #ffffff;
}

.form-section h4 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #007bff;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #495057;
}

.event-management input,
.event-management textarea {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.event-management input:focus,
.event-management textarea:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.checkbox-group {
    flex-direction: row;
    align-items: center;
}

.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
    margin-right: 10px;
}

.image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.image-preview img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
}

.event-management button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.event-management button:hover {
    background-color: #0056b3;
}

.ticket-type {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.event-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.event-item {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #fff;
}

.event-item.featured {
    border-color: #ffd700;
    background-color: #fffdf0;
}

.featured-label {
    background-color: #ffd700;
    color: #000;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.8em;
    margin-left: 10px;
}

.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.checkbox-label input[type="checkbox"] {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.event-item ul {
  list-style-type: none;
  padding-left: 0;
}

.event-item li {
  margin-bottom: 10px;
}

.ticket-type-details {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.ticket-type-count {
  font-size: 0.9em;
  color: #666;
}

/* New styles for ticket types */
.ticket-types-container {
  margin-top: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
}

.ticket-types-header {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.ticket-type-list {
  list-style-type: none;
  padding: 0;
}

.ticket-type-item {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.ticket-type-title {
  font-weight: bold;
  color: #007bff;
  margin-bottom: 5px;
}

.ticket-type-price {
  font-size: 1.1em;
  color: #28a745;
  margin-bottom: 5px;
}

.ticket-type-counts {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  color: #666;
}

.ticket-type-count {
  background-color: #e9ecef;
  padding: 3px 8px;
  border-radius: 4px;
  margin-right: 5px;
}

.ticket-type-count.reserved {
  color: #dc3545;
}

.ticket-type-count.total {
  color: #17a2b8;
}

.ticket-type-count.created {
  color: #6610f2;
}

.event-item:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.event-item h4 {
  color: #333;
  margin-top: 0;
}

.event-total-counts {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 6px;
  margin-bottom: 15px;
}

.event-total-count {
  font-size: 0.9em;
  color: #495057;
}

.event-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 15px 0;
}

.primary-actions, .secondary-actions {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.edit-btn, .delete-btn {
  flex: 1;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.edit-btn {
  background-color: #28a745;
}

.edit-btn:hover {
  background-color: #218838;
}

.delete-btn {
  flex: 1;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-btn:hover {
  background-color: #c82333;
}

.event-actions .primary-actions .delete-btn {
  background-color: #dc3545;
  color: white;
}

.event-actions .primary-actions .delete-btn:hover {
  background-color: #c82333;
}

.toggle-btn {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggle-btn:hover {
  background-color: #e0e0e0;
}

/* Add these styles to your existing CSS */
.ticket-type-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.ticket-type-item {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 1rem;
}

.ticket-type-item h6 {
  margin: 0 0 0.5rem 0;
  color: #495057;
}

.ticket-type-item p {
  margin: 0.25rem 0;
  color: #6c757d;
}
