.admin-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  color: #333;
}

.card-creation, .voucher-creation {
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input, select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.admin-page button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-page  button:hover {
  background-color: #0056b3;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.card-item {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
}

.error-message {
  color: red;
  margin: 0;
  padding: 0;
}

.return-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.return-button:hover {
  background-color: #45a049;
}

.assign-card {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.assign-card select {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.auto-assign-toggle {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.auto-assign-toggle input[type="checkbox"] {
  margin-right: 10px;
}

.auto-assign-toggle label {
  font-size: 14px;
  color: #333;
}

.admin-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.admin-tabs button {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.admin-tabs button.active {
  background-color: #007bff;
  color: white;
}
