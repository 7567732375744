.add-to-home-screen-prompt {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #1E293B; /* Dark blue background */
    border-radius: 15px 15px 0 0;
    box-shadow: 0 -8px 20px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 100%;
    width: 100%;
    z-index: 1005;
    color: white;
    text-align: center;
    max-height: 80vh; /* Limit height to 80% of viewport height */
    overflow-y: auto; /* Add scrolling if content exceeds max-height */
    box-sizing: border-box; /* Include padding in the element's total width and height */
}

.add-to-home-screen-prompt h2 {
    margin-top: 0;
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
}

.add-to-home-screen-prompt p {
    margin-bottom: 20px;
    color: #94A3B8; /* Light gray for secondary text */
    font-size: 16px;
}

.ios-instructions, .android-instructions {
    margin-bottom: 15px;
    background-color: #2C3E50; /* Slightly lighter blue for instruction boxes */
    border-radius: 10px;
    padding: 10px;
    text-align: left;
    word-wrap: break-word;  
    hyphens: auto;
}
.ios-instructions .icon {
    margin-left: 5px;
    margin-right: 5px;
}

.ios-instructions p, .android-instructions p {
    margin-bottom: 10px;
    text-align: left;
}

.ios-instructions ol, .android-instructions ol {
    padding-left: 20px;
    margin-bottom: 0;
}

.ios-instructions li, .android-instructions li {
    margin-bottom: 10px;
    line-height: 1.4;
}

.ios-instructions li:last-child, .android-instructions li:last-child {
    margin-bottom: 0;
    padding-bottom: 20px; /* Add padding to the bottom of the last list item */
}

.icon {
    font-size: 1.2em;
    margin-left: 5px;
    vertical-align: middle;
}

.install-button, .dismiss-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 25px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.install-button {
    background-color: #4ADE80; /* Green color for the install button */
    color: #1E293B; /* Dark text on light button */
}

.install-button:hover {
    background-color: #22C55E; /* Darker green on hover */
}

.dismiss-button {
    background-color: #334155; /* Slightly lighter than the main background */
    color: white;
}

.dismiss-button:hover {
    background-color: #475569; /* Even lighter on hover */
}

.ios-instructions p.instruction-title, 
.android-instructions p.instruction-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
}