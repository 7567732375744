.event-list-page {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 62px); /* Adjust for the header height */
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .nav {
    display: flex;
    gap: 1rem;
  }
  
  .nav-link {
    text-decoration: none;
    color: #333;
    font-size: 0.875rem;
  }
  
  .main-content {
    min-height: calc(100vh - 41px);
    flex: 1;
  }
  
  .hero-section {
    padding: 1rem 1rem;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  

  .hero-content {
    max-width: 800px;
    margin-bottom: 2rem;
  }
  
  .hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .hero-description {
    font-size: 1.125rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .hero-cta {
    font-size: 1.125rem;
    font-weight: 500;
    color: #333;
  }
  
  .featured-event {
    margin-top: 2rem;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  }
  
  .featured-event-link {
    display: block;
    text-decoration: none;
    color: inherit;
  }
  
  .featured-event-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .featured-event-content {
    padding: 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #ffffff; /* Ensure the content area is also white */
  }
  
  .featured-event-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
  }
  
  .featured-event-description {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .event-category {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    background-color: #e0e0e0;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  
  .event-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .event-date,
  .event-time,
  .event-location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
  }
  
  .event-button {
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .event-button:hover {
    background-color: #555;
  }
  
  .events-section {
    background-color: #f0f0f0;
    padding: 3rem 1rem;
  }

  .past-events-section {
    background-color: white;
    padding: 3rem 1rem;
  } 
  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .section-description {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 1rem;
    color: #666;
  }
  
  .events-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 2rem;
    justify-content: center;
  }

  .event-card {
    flex: 0 0 calc(33.333% - 2rem); /* Subtracting the gap */
    max-width: calc(33.333% - 2rem);
    min-width: 250px; /* Ensuring a minimum width for very large screens */
  }
  
  @media (max-width: 1024px) {
    .event-card {
      flex: 0 0 calc(50% - 2rem);
      max-width: calc(50% - 2rem);
    }
  }
  
  @media (max-width: 640px) {
    .event-card {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  /* Center grid items when fewer than 3 (or 2 on medium screens) */
  .events-grid:has(:nth-child(1):last-child) {
    grid-template-columns: minmax(0, 400px);
  }
  
  @media (max-width: 520px) {
    .events-grid:has(:nth-child(2):last-child) {
      grid-template-columns: minmax(0, 400px);
    }
  }
  
  .event-card {
    background-color: #ffffff; /* Changed to white background */
    border-radius: 0.5rem;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .event-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .event-content {
    box-sizing: border-box;
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
  }
  
  .event-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .event-description {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .event-date,
  .event-location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #666;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .page-button {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    color: #333;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .page-button:hover {
    background-color: #e0e0e0;
  }
  
  .page-button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  @media (min-width: 768px) {
    .hero-section .container {
      display: grid;
      grid-template-columns: 1fr 400px;
      gap: 2rem;
    }
  }
  
  @media (min-width: 1024px) {
    .hero-section .container {
      grid-template-columns: 1fr 500px;
    }
  }

  @media (max-width: 768px) {
    .hero-title{
      margin-top: 0px;
    }
  }

  .event-filter {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .event-filter label {
    margin-right: 1rem;
    font-weight: 600;
    color: #333;
  }

  .event-filter select {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 2px solid #e0e0e0;
    border-radius: 0.25rem;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 1rem auto;
    min-width: 200px;
  }

  .event-filter select:hover,
  .event-filter select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }






