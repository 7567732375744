.checkout-container {
    max-width: 600px;
    width: 90%;
    margin:1rem;
    padding: 0 1rem 2rem 1rem;
    background-color: #f8f9fa;
    
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
  }
  
  .checkout-title {
    color: #333;
    font-size: 28px;
    margin-top: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  .checkout-summary {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
  }
  
  .checkout-summary h3 {
    margin-top: 0;
    color: #333;
  }
  
  .checkout-item {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 4 equal columns */
    padding: 0;
    margin-bottom: 1rem;
    color: #555;
    text-align: center; 
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;

  }

  .checkout-item-title {
    margin-right: 1.5rem;
    grid-column: span 4;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .checkout-item-price {
    grid-column: span 2;
    white-space: nowrap;
    text-align: right;
    align-self: center;
  }

  .checkout-item-quantity {  
    white-space: nowrap;
    align-self: center;
  }
  

  .checkout-total {
    justify-content: space-between;
    font-size: 1em;
    color: #333;
    text-align: left;
  }

  .checkout-total-item {
    margin-bottom: 0.5rem;
  }

  .checkout-total-item:last-child {
    margin-bottom: 0;
  }
  


@media (max-width: 500px) {
  
  .checkout-item {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    padding: 0;
    margin-bottom: 1rem;
    color: #555;
    text-align: center; 
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;

  }

  .checkout-item-title {
    padding-bottom: 1rem;
    grid-column: span 3;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .checkout-item-price {
    white-space: nowrap;
    text-align: right;
  }

  .checkout-item-quantity {  
    white-space: nowrap;
  }
  

  .checkout-total {
    justify-content: space-between;
    font-size: 1em;
    color: #333;
    text-align: left;
  }
}


@media (max-width: 250px) {
  .checkout-item {
    display: grid;
    grid-template-columns: repeat(2, auto); 
  }

  .checkout-item-price {
    grid-column: span 2;
  }
}




  .payment-method-selection {
    margin-bottom: 30px;
  }
  
  .payment-method-selection h3 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .payment-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  
  .payment-option {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 80%;
    justify-content: center;
  }
  
  .payment-option:hover {
    background-color: #f0f0f0;
  }
  
  .payment-option.selected {
    background-color: #e6f7ff;
    border-color: #1890ff;
  }
  
  .payment-option input {
    margin-right: 15px;
  }
  
  .payment-icon {
    font-size: 1.4em;
    margin-right: 15px;
  }
  
  .checkout-container .checkout-button {
    width: 80%;
    padding: 15px;
    background-color: #e53935;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 0;
  }
  
  .checkout-button:hover {
    background-color: #40a9ff;
  }
  
  .checkout-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .error {
    color: #ff4d4f;
    margin-top: 20px;
    text-align: center;
  }
  
  .checkout-header {
    display: flex;
    justify-content: center;  /* Center the content horizontally */
    align-items: center;
    position: relative;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .close-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .close-icon path {
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
