.ticket-type-form {
  margin-top: 20px;
}

.ticket-type-form h4 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.5em;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.ticket-type-form .ticket-type-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: box-shadow 0.3s ease;
}

.ticket-type-form .ticket-type-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.ticket-type-form .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 8px 8px 0 0;
}

.ticket-type-form .card-header h5 {
  margin: 0;
  color: #007bff;
  font-size: 1.2em;
}

.ticket-type-form .card-content {
  padding: 20px;
}

.ticket-type-form .form-row {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.ticket-type-form .form-group {
  flex: 1 1 200px;
  margin: 10px;
}

.ticket-type-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.ticket-type-form .form-group input,
.ticket-type-form .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.ticket-type-form .form-group input:focus,
.ticket-type-form .form-group textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

.ticket-type-form .form-group textarea {
  resize: vertical;
}

.ticket-type-form .remove-ticket-type,
.ticket-type-form .add-ticket-type {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.ticket-type-form .remove-ticket-type {
  background-color: #dc3545;
  color: white;
}

.ticket-type-form .remove-ticket-type:hover {
  background-color: #c82333;
  transform: translateY(-1px);
}

.ticket-type-form .add-ticket-type {
  background-color: #28a745;
  color: white;
  width: 100%;
  margin-top: 20px;
}

.ticket-type-form .add-ticket-type:hover {
  background-color: #218838;
  transform: translateY(-1px);
}
