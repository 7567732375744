.password-reset-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f5f5f5;
}

.password-reset-form {
    position: relative;
    width: 100%;
    max-width: 400px;
    padding: 30px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.password-reset-container h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
}

.password-reset-form .form-group {
    margin-bottom: 20px;
    position: relative;
}

.password-reset-form .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.password-reset-form .form-group input {
    width: 100%;
    padding: 10px;
    padding-right: 40px; /* Add space for the toggle button */
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.password-reset-form button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.password-reset-form button:hover {
    background-color: #0056b3;
}

.password-reset-form .message {
    text-align: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 4px;
    color: #333;
}

.password-reset-form .password-rules {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
    margin-bottom: 0;
    font-size: 0.9em;
}

.password-reset-form .password-rules.visible {
    max-height: 200px;
    opacity: 1;
    margin-bottom: 10px;
}

.password-reset-form .password-rules ul {
    list-style-type: none;
    padding-left: 0;
    margin: 5px 0;
}

.password-reset-form .password-rules li {
    margin-bottom: 5px;
    color: #666;
}

.password-reset-form .password-rules li.met {
    color: #4CAF50;
}

.password-reset-form .password-rules li::before {
    content: '•';
    margin-right: 5px;
}

.password-reset-form .password-rules li.met::before {
    content: '✓';
    color: #4CAF50;
}

.password-reset-form .password-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.password-reset-form .password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    color: #666;
    padding: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.password-reset-close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    padding: 5px;
    line-height: 1;
}

.password-reset-close-button.back-button {
    width: auto;
    padding: 5px;
}

@media (max-width: 600px) {
    .password-reset-container {
        padding: 0;
        background-color: white;
    }

    .password-reset-form {
        max-width: 90%;
        box-shadow: none;
        border-radius: 0;
        padding-top: 50px;
    }
}