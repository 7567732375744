.card-display-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1010;
}

.card-display {
    position: fixed;
    z-index: 1011;
    top: 20%;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
    animation: slideUp 0.3s ease-out;
    overflow: hidden;
}

@keyframes slideUp {
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
}

.card-display-close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    z-index: 1;
}

.card-display-header {
    background-color: #d0d0d0;
    padding: 15px;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 2;
}

.card-display-header h2 {
    margin: 0;
    font-size: 1.2rem;
}

.card-display-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
}

.card-display-card {
    width: 80%;
    background-color: #9dd1a2;
    color: black;
    border-radius: 1rem;
    padding: 0.7rem 1rem;
    margin: 0 auto 15px;
    position: relative;
}

.card-display-name {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0 0 0.2rem 0;
}

.card-display-number {
    font-size: 0.875rem;
    margin: 0;
}

.card-display-vouchers {
    font-size: 1.15rem;
    margin: 0.3rem 0;
}

.card-display-available {
    font-size: 0.75rem;
    color: #666;
    margin: 0;
}

.card-display-valid-date {
    font-size: 0.75rem;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    margin-bottom: 0;
    color: #666;
}

.card-display-qr-icon img {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 24px;
    height: 24px;
}

.card-display-divider {
    width: 70%;
    border-top: 3px dashed #ddd;
    margin: 20px auto;
}

.card-display-details {
    text-align: left;
}

.card-display-details h4 {
    margin-top: 0;
}
