.referral-system {
  margin-top: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
}

.referral-system .referral-code-list {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.referral-system .referral-code-item {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.referral-system .referral-code-info {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.referral-system .referral-code-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.referral-system .generate-referral-btn,
.referral-system .copy-referral-btn,
.referral-system .show-orders-btn,
.referral-system .delete-referral-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
  font-weight: bold;
}

.referral-system .generate-referral-btn {
  background-color: #28a745;
}

.referral-system .generate-referral-btn:hover {
  background-color: #218838;
}

.referral-system .copy-referral-btn {
  background-color: #17a2b8;
}

.referral-system .copy-referral-btn:hover {
  background-color: #138496;
}

.referral-system .show-orders-btn {
  background-color: #6c757d;
}

.referral-system .show-orders-btn:hover {
  background-color: #5a6268;
}

.referral-system .delete-referral-btn {
  background-color: #dc3545;
}

.referral-system .delete-referral-btn:hover {
  background-color: #c82333;
}

.referral-system .copy-success {
  margin-top: 10px;
  padding: 8px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
}

.order-details {
  margin-top: 15px;
  padding: 15px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.order-summary {
  background-color: #e9ecef;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.order-summary p {
  margin: 5px 0;
  font-weight: bold;
}

.order-summary p:last-child {
  margin-top: 10px;
  padding-top: 5px;
  border-top: 1px solid #ced4da;
}

.order-item {
  margin-bottom: 15px;
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.order-item p {
  margin: 5px 0;
}

.ticket-summary {
  margin-top: 10px;
}

.ticket-summary h6 {
  margin-bottom: 5px;
  font-weight: bold;
}

.ticket-list {
  list-style-type: none;
  padding-left: 0;
}

.ticket-list li {
  margin-bottom: 5px;
  padding: 8px;
  background-color: #f1f3f5;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket-list li span {
  flex: 1;
  text-align: left;
}

.ticket-list li span:first-child {
  font-weight: bold;
}

.ticket-list li span:last-child {
  text-align: right;
}
