.auth-popup{
  height: 100%;
}

.auth-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.auth-popup-message {
  margin: 0;
  text-align: center;
  font-size: 1.2em;
  color: #333;
}

.auth-popup {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  height: auto; /* Change from fixed height to auto */
  max-height: 90vh; /* Set a maximum height */
  overflow-y: auto;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

.auth-popup-content {
  display: flex;
  flex-direction: column;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

.auth-popup .auth-form-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
}

.auth-popup .auth-popup-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
}

.auth-popup .auth-popup-header h1 {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.auth-popup .auth-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.auth-popup input[type="text"],
.auth-popup input[type="email"],
.auth-popup input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.auth-popup .create-account-button,
.auth-popup .sign-in-button {
  display: block;
  width: 100%;
  padding: 12px;
  margin: 20px 0;
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.auth-popup .create-account-button:hover,
.auth-popup .sign-in-button:hover {
  background-color: #c53030;
}

.auth-popup .terms-agreement {
  font-size: 0.9em;
  text-align: center;
  margin-top: 15px;
}

.auth-popup .auth-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  width: 100%;
}

.auth-popup .auth-divider::before,
.auth-popup .auth-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.auth-popup .auth-divider span {
  padding: 0 10px;
  background-color: white;
  color: #666;
}

.back-button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: currentColor;
}

.back-arrow {
  font-size: 24px;
  line-height: 1;
}

.auth-switch {
  margin: 0;
  text-align: center;
}

.auth-switch button {
  margin: 0;
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.auth-switch p {
  margin: 0;
}

.auth-switch button:hover {
  color: #0056b3;
}

@media (max-width: 600px) {
  .auth-popup-overlay {
    padding: 0;
    align-items: flex-start;
  }

  .auth-popup {
    height: 100%;
    min-height: 100vh;
    /* Add this line for iOS */
    min-height: -webkit-fill-available;
    max-height: none;
    border-radius: 0;
    margin: 0;
  }
  
  .auth-popup-overlay {
    align-items: flex-start;
  }
}

@media (min-width: 601px) {
  .auth-popup {
    max-width: 500px;
    min-height: 400px; /* Add a minimum height for larger screens */
  }
}
