.basket-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  overflow-y: auto;
}

.basket-popup-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 600px;
  width: 90%;
  margin: 1rem;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.basket-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative; /* Add this */
}

.basket-title {
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: bold;
  flex-grow: 1; /* Add this */
  text-align: center; /* Add this */
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  position: absolute; /* Add this */
  right: 0; /* Add this */
  top: 50%; /* Add this */
  transform: translateY(-50%); /* Add this */
}

.close-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.basket-items {
  border: 1px solid #e2e8f0;
  padding: 0.5rem;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.basket-item {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;  
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
}

.basket-item-details {
  display: grid;
  gap: 0.25rem;
}

.basket-item-title {
  font-weight: 500;
  word-break: break-word;
  overflow-wrap: break-word;
}

.basket-item-price {
  color: #64748b;
  font-size: 1.25rem;
  font-weight: bold;

  padding-left: 5px;
  padding-right: 5px;
}

.basket-popup-content h2 {
  font-size: 1.25rem; /* Adjust font size for mobile */
}

.basket-item-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantity-button {
  background-color: #f1f5f9;
  border: none;
  border-radius: 0.25rem;
  padding: 0.25rem;
  cursor: pointer;
}

.quantity-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity-value {
  text-align: center;
  min-width: 1.5rem;
}

.remove-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
}

.basket-summary {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.summary-total {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e2e8f0;
}

.checkout-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 1rem;
  background-color: #e53935;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.checkout-button:hover {
  background-color: #d32f2f;
}

@media (max-width: 450px) {
  .basket-popup {
    align-items: flex-start;
  }

  .basket-popup-content {
    width: 100%;
    max-height: 88vh;
    padding: 1rem;
  }

  .basket-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
  }

  .basket-items {
    max-height: calc(100vh - 350px); /* Adjust this value based on your header and footer height */
    overflow-y: auto;
  }

  .basket-summary {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding-top: 1rem;
    margin-top: 1rem;
    z-index: 1;
  }

  .checkout-button {
    position: sticky;
    bottom: 0;
    margin-top: 1rem;
  }
}