.trial-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .trial-popup {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .trial-popup h2 {
    margin-top: 0;
    color: #11a120;
  }
  
  .trial-popup p {
    margin-bottom: 1.5rem;
  }
  
  .trial-popup .dismiss-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 25px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #334155;
    color: white;
}
  .trial-popup .dismiss-button:hover {
    background-color: #475569;
  }