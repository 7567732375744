html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

body::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

#root {
    min-height: 100vh;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 40px);
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.main-content {
    flex: 1 0 auto;
}

.footer {
    flex-shrink: 0;
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
    height: 40px; /* Adjust this value if needed */
    position: relative;
    z-index: 99;
}

/* Ensure the auth popup overlay is above the footer */
.auth-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* ... existing styles ... */

body.body-lock {
    overflow: hidden;
}

.body-lock .App {
    min-height: calc(100vh - 40px);
    overflow: hidden;
}

/* Remove these styles as they're not needed anymore */
/*
.body-lock .main-content {
    overflow-y: scroll;
    padding-right: 17px;
}
*/

.voucher-active .main-content {
    pointer-events: none;
}

.voucher-active .voucher-display {
    pointer-events: auto;
}

body.overlay-active {
    padding-right: 24px; /* Adjust this value based on the scrollbar width in your browser */
}


.initial-loading,
.react-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f7f7f7;
    z-index: 9999;
}

.initial-loading-spinner,
.react-loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: #e53e3e;
    animation: spin 1s ease-in-out infinite;
}


  
  .react-loading-title {
    font-family: Arial, sans-serif;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  

  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }

@keyframes spin {
  to { transform: rotate(360deg); }
}

.maintenance-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f0f0f0;
    color: #333;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.maintenance-page h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #e53e3e; /* Highlighted color for the title */
}

.maintenance-page p {
    font-size: 18px;
    max-width: 600px;
    line-height: 1.5;
}
