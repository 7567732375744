.event-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 41px);
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.event-page .event-carousel {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.event-page .carousel-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.event-page .carousel-slide.active {
  opacity: 1;
}

.event-page .carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-page .event-description .large-text { font-size: 1.2em; }

.event-page .event-description-h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.event-page .event-description-h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.event-page .event-description-h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.event-page .carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.event-description-container{
  gap: 0;
}

.event-page .carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.event-page .carousel-button.prev {
  left: 20px;
}

.event-page .carousel-button.next {
  right: 20px;
}

.event-page .event-content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  box-sizing: border-box; /* Include padding in the width calculation */
}

.event-page .event-details {
  flex: 2;
}

.event-page .event-sidebar {
  flex: 1;
}


.event-page .event-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
  display: inline-block; /* Allow the title to be inline */
}

.copy-notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  animation: fadeInOut 3s ease-in-out;
  z-index: 2000;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

.event-title-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  margin-bottom: 16px;
}

.share-button {
  width: 48px; /* Increased size */
  height: 48px; /* Increased size */
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  margin-left: 16px; /* Add some space between title and button */
}

.share-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.share-button img {
  width: 48px; /* Doubled size */
  height: 48px; /* Doubled size */
}

.event-page .event-description {
  font-size: 1.125rem;
  color: #666;
  margin-bottom: 24px;
}

.event-page .event-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.event-page .event-info-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.event-page .icon {
  width: 24px;
  height: 24px;
  color: #666;
}

.event-page .info-title {
  font-weight: 500;
  margin-bottom: 4px;
}

.event-page .info-description {
  color: #666;
}

.event-page .event-sidebar {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.event-page .vendor-info {
  margin-bottom: 16px;
}

.event-page .ticket-types {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.event-page .ticket-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000000; /* Ensure text is always black for readability */
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.5); /* Add a subtle white shadow for better contrast */
  width: auto;
}

.event-page .ticket-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.event-page .ticket-info {
  margin-bottom: 16px;
}


.event-page .ticket-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.event-page .view-ticket-button {
  background-color: #f0f0f0;
  color: #e53935;
  border: 1px solid #e53935;
  padding: 8px 12px;
  font-size: 0.875rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.event-page .add-to-basket-button {
  background-color: #e53935;
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 0.875rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: auto;
}

.event-page .buy-ticket-button {
  width: 100%;
  padding: 10px 16px;
  background-color: #e53935;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.event-page .buy-ticket-button:hover {
  background-color: #c62828;
}

.event-page .view-ticket-button:hover {
  background-color: #e53935;
  color: white;
}

.event-page .add-to-basket-button:hover {
  background-color: #c62828;
}

.ticket-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
}

.ticket-popup {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  margin: 20px;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.ticket-popup h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.ticket-popup-price {
  font-size: 1.25rem;
  font-weight: bold;
  color: #e53935;
  margin-bottom: 1rem;
}

.ticket-popup-description {
  margin-bottom: 1.5rem;
}

.ticket-popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.ticket-popup-buttons button {
  flex: 1;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ticket-popup-buttons button:first-child {
  background-color: #e53935;
  color: white;
}

.ticket-popup-buttons button:first-child:hover {
  background-color: #d32f2f;
}

.ticket-popup-buttons button:last-child {
  background-color: #f0f0f0;
  color: #333;
}

.ticket-popup-buttons button:last-child:hover {
  background-color: #e0e0e0;
}

@media (max-width: 767px) {

  .event-page .event-content {
    padding: 1rem 1rem 6rem;
  }

  .event-page .event-details,
  .event-page .event-sidebar {
    width: 100%;
  }

}

@media (min-width: 768px) {
  .event-page .event-content {
    flex-direction: row;
  }

  .event-page .event-details {
    flex: 2;
    padding-right: 1rem;
  }

  .event-page .event-sidebar {
    flex: 1;
  }
}

@media (max-width: 1200px) {
  .event-page .event-carousel,
  .event-page .event-content {
    width: 100%;
  }
}

.floating-basket-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #e53935;
  color: white;
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

@media (min-width: 1200px) {
  .floating-basket-button {
    right: calc((100% - 1200px) / 2 + 20px); /* Align with max-width of 1200px */
  }
}

.basket-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  grid-column: span 2; /* Ensure it spans across the grid columns */
}

.open-basket-button {
  background-color: #e53935;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.open-basket-button:hover {
  background-color: #c62828;
}

.ticket-price {
  font-size: 1.25rem; /* Emphasize the price */
  font-weight: bold; /* Make the price bold */
  color: #e53935; /* Set the color to match the theme */
  text-align: right; /* Align the price to the right */
}

body.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.ticket-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.ticket-button {
  flex: 1;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ticket-button.view-details {
  background-color: #f0f0f0;
  color: #333;
  margin-right: 5px;
}

.ticket-button.add-to-basket {
  background-color: #e53935;
  color: white;
  margin-left: 5px;
}

.ticket-button.add-to-basket:hover {
  background-color: #c62828;
}

.ticket-button:hover {
  opacity: 0.9;
}

.ticket-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #ccc;
  color: #666;
}

.ticket-button:disabled:hover{
  background-color: #ccc;
  color: #666;
}
