.login-container {
  padding: 0 20px;
  font-family: 'Roboto', Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form input[type="email"],
.login-form input[type="password"] {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.remember-me label {
  margin-right: 10px;
  font-size: 14px;
}

.remember-me input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-family: 'Roboto', Arial, sans-serif; /* Match the font family */
  font-size: 14px; /* Adjust the font size */
  font-weight: 500; /* Add some weight to the text */
  letter-spacing: 0.5px; /* Optional: add some letter spacing */
}

.login-button:hover {
  background-color: #0056b3;
}

.login-title {
  padding-bottom: 20px;
  padding-top: 0px;
  margin-top: 0px;
}

.forgot-password-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
  font-size: 0.9em;
}

.forgot-password-button:hover {
  color: #0056b3;
}

.success-message {
  color: #28a745;
  margin-bottom: 10px;
}
