.ticket-type-display .ticket-types-container {
  margin-top: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
}

.ticket-type-display .ticket-types-header {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.ticket-type-display .ticket-type-list {
  list-style-type: none;
  padding: 0;
}

.ticket-type-display .ticket-type-item {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.ticket-type-display .ticket-type-title {
  font-weight: bold;
  color: #007bff;
  margin-bottom: 5px;
}

.ticket-type-display .ticket-type-price {
  font-size: 1.1em;
  color: #28a745;
  margin-bottom: 5px;
}

.ticket-type-display .ticket-type-counts {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 0.9em;
  color: #666;
}

.ticket-type-display .ticket-type-count {
  background-color: #e9ecef;
  padding: 3px 8px;
  border-radius: 4px;
}

.ticket-type-display .ticket-type-count.reserved {
  color: #dc3545;
}

.ticket-type-display .ticket-type-count.total {
  color: #17a2b8;
}

.ticket-type-display .ticket-type-count.created {
  color: #6610f2;
}
