.voucher-card {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 180px;
    position: relative;
    border: 1px solid var(--border);
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.voucher-card .voucher-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.voucher-card .voucher-image {
    position: relative;
    height: 150px;
    overflow: hidden;
}

.voucher-card .voucher-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.voucher-card .company-logo {
    position: absolute;
    top: 43%;
    left: 2%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.voucher-card .company-logo img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.voucher-card .voucher-content {
    flex-grow: 1;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
}

.voucher-content h4 {
    margin: 0 0 0.25rem 0;
    font-size: 0.9rem;
    color: #333;
    font-weight: 600;
}

.voucher-content .discount {
    margin: 0;
    font-size: 1.2rem;
    color: #e53935;
    font-weight: bold;
}

.voucher-content .details {
    margin: 0;
    font-size: 0.7rem;
    color: #666;
}

.voucher-content .company-description {
    font-size: 0.7rem;
    color: #666;
    margin: 0 0 0.4rem 0;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.voucher-content .voucher-description {
    font-size: 0.9rem;
    color: #333;
    margin: 0 0 0.7rem 0;
    font-weight: 600;
    line-height: 1.3;
    display: -webkit-box;
    line-clamp: 2; 
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.voucher-content .details {
    font-size: 0.75rem;
    margin: 0;
    text-align: right;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}


