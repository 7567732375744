.footer {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
    flex-wrap: wrap;
    width: 100%;
    z-index: 99;
}

.footer a, .footer span {
    margin: 0 10px;
    color: #6c757d;
    text-decoration: none;
    font-size: 12px;
}

.footer a:hover {
    color: #007bff;
}
