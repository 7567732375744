.voucher-management {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.voucher-management .voucher-form-container {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.voucher-management .voucher-form-preview {
    display: flex;
    gap: 30px;
}

.voucher-management .voucher-form {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.voucher-management .voucher-preview {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.voucher-management .voucher-preview h3 {
    margin-bottom: 15px;
}


.voucher-management .voucher-preview .voucher-card {
    max-height: 255px;
    width: 100%;
    margin-bottom: 20px;
}

.voucher-management .voucher-display-preview {
    width: 100%;
    max-width: 300px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-top: 20px;
}

.voucher-management .voucher-display-preview .voucher-display {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    max-height: 500px;
    overflow-y: auto;
}

.voucher-management .voucher-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.voucher-management .form-group {
    display: flex;
    flex-direction: column;
}

.voucher-management .form-row {
    display: flex;
    gap: 15px;
}

.voucher-management .form-row .form-group {
    flex: 1;
}

.voucher-management label {
    margin-bottom: 5px;
    font-weight: bold;
}

.voucher-management input, select, textarea {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
}

.voucher-management textarea {
    min-height: 100px;
    resize: vertical;
}

.voucher-management .image-preview {
    margin-top: 10px;
}

.voucher-management .image-preview img {
    max-width: 200px;
    border-radius: 4px;
}

.voucher-management .form-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.voucher-management .btn-primary, .btn-secondary, .btn-edit, .btn-delete {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.voucher-management .btn-primary {
    background-color: #007bff;
    color: white;
}

.voucher-management .btn-secondary {
    margin: 1rem;
    background-color: #6c757d;
    color: white;
}

.voucher-management .btn-edit {
    background-color: #ffc107;
    color: #212529;
}

.voucher-management .btn-delete {
    background-color: #dc3545;
    color: white;
}

.voucher-management .success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.voucher-management .error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.voucher-management .voucher-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.voucher-management .voucher-item {
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.voucher-management .voucher-item .voucher-card {
    width: 100%;
}

.voucher-management .voucher-actions {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.voucher-management .voucher-card h3 {
    margin-top: 0;
    color: #007bff;
}

.voucher-management .voucher-card p {
    margin: 5px 0;
    font-size: 14px;
}

.voucher-management .voucher-image {
    max-width: 100%;
}

.voucher-management .voucher-list {
    margin-top: 30px;
    display: table-row;
}

.voucher-management .card-selector {
    margin-bottom: 20px;
}

.voucher-management .card-selector label {
    margin-right: 10px;
}

.voucher-management .card-selector select {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
}

