.qr-code-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.qr-code-popup {
  max-width: 400px;
  margin: 20px;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

.qr-code-popup h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  word-break: break-word;
  overflow-wrap: break-word;
}

.qr-code-popup p {
  margin-top: 1rem;
}

  .qr-close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}