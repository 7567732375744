.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--border);
  background-color: var(--background);
  height: 62px;
  box-sizing: border-box;
}

.header .content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--foreground);
}

.logo-image {
  height: 40px; /* Adjust this value to fit your design */
  width: auto;
  object-fit: contain;
  display: block; /* This ensures the image is treated as a block element */
}

.header-right {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 5px;
}

.nav-links a {
  text-decoration: none;
  color: var(--foreground);
}

.settings-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--secondary);
  color: var(--secondary-foreground);
  transition: background-color 0.3s ease;
}

.settings-button:hover {
  background-color: var(--accent);
}

.settings-button svg {
  width: 24px;
  height: 24px;
}

.verify-ticket-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 50%;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.verify-ticket-button svg {
  width: 24px;
  height: 24px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-right {
    flex-direction: row;
    align-items: center;
  }

  .nav-links {
    flex-direction: row;
    align-items: center;
  }

  .verify-ticket-button {
    margin-top: 0;
  }
}

/* Add this new style for the Link containing the logo */
.header .content-wrapper > a {
  display: flex;
  align-items: center;
  height: 100%;
}
