.about-us {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.about-us h1 {
  text-align: center;
  color: #007bff;
  margin-bottom: 30px;
}

.about-us h2 {
  color: #0056b3;
  margin-top: 30px;
  margin-bottom: 15px;
}

.about-us p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.about-us ul {
  padding-left: 20px;
}

.about-us li {
  margin-bottom: 10px;
}

.back-link {
  display: inline-block;
  margin-top: 30px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-link:hover {
  background-color: #0056b3;
}
