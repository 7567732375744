@media screen and (min-width: 768px) {
    body {
      background-color: #f0f0f0;
    }
  
    #root {
      margin: 0 auto;
      background-color: white;
      min-height: 100vh;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }